<template>
    <div class="enterhistory">
            <span class="title-text">
                تاریخچه ورود
            </span>
            <div class="table-container">
                <perfect-scrollbar @ps-y-reach-end="check">
                <table>
                    <tr class="thead">
                        <td> زمان </td>
                        <td> آی پی </td>
                        <td> نوع دستگاه </td>
                        <td> پلتفرم </td>
                        <td> نوع مرورگر </td>
                    </tr>
                    <tbody>
                    <tr v-for="(item,index) in tableContents" :key="index" class="tbody">
                        <td> {{$G2J(item.loginRequestedDateTime)}} </td>
                        <td> {{item.ipAddress}} </td>
                        <td> {{item.deviceType}} </td>
                        <td> {{item.osType}} </td>
                        <td> {{item.browser}} </td>
                    </tr>
                    </tbody>
                </table>
                </perfect-scrollbar>
            </div>
    </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
    export default {
        name:'EnterHistory',
        component:{
            PerfectScrollbar
        },
        data() {
            return {
                tableContents:[],
                page:0,
                lastPage:1,
            }
        },
        methods:{
            async check() {
                this.state.loading = true
                if (this.page < this.lastPage) { //for scroll loading
                    this.page++ //for scroll loading
                    const [res,] = await this.$http.get('/users/login-history', {
                        params: {
                            size: 10,
                            page: this.page
                        }
                    })
                    if (res) {
                        this.lastPage = res.totalPages
                        this.tableContents = [...this.tableContents, ...res.content] //for scroll loading
                        // this.tableContents = res.content //for pagination
                    }
                }
                this.state.loading = false
            },
        },
        mounted() {
            this.check();
        },
    }
</script>

<style lang="scss" scoped>
.table-container{
    height: 100%;
    max-height: 400px;
    overflow: scroll;
}
.thead{
        border-bottom: 1px solid #cacacead;
        position: sticky;
        top: 0;
        background: white;
    td{
        text-align: center;
        color: var(--grey);
        height: 100%;
        min-height: 37px;
        font-size: clamp(14px,1vw,12px);
        font-weight: 400;
    }
}
tr{
    height: 60px;
    border-bottom: 1px solid var(--light-grey);
}
tbody{
    height: 100%;
    overflow: scroll;
    td{
        text-align: center;
        font-size: clamp(14px,1vw,12px);
        font-weight: 400;
        color: var(--black);

    }
}
table{
    border-collapse: collapse;
    width: 100%;
    height: 100%;
}
.enterhistory{
    height: 100%;
    border-radius: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    row-gap: 24px;
    background: white;
    min-height: 200px;
}
.title-text{
    color: var(--black-fee);
    word-spacing: 4px;
}
// @media only screen and(max-width:1700px){
//     .enterhistory{
//         max-height: 100px;
//         // overflow: hidden;
//     }
// }
</style>