<template>
    <div class="google-container">
        <p> 
        لطفا بارکد زیر را توسط برنامه 
        GOOGLE AUTHENTICATOR 
        گوشی خود اسکن نمایید <br />
        چنانچه موفق نشدید کد را به صورت دستی وارد کنید
        </p>
        <p> 
            پس از اسکن بارکد،مورد مربوط به سایت در برنامه شما ایجاد میشود برای تایید صحت فرآیند، کدی که در اپ خود مشاهده میکنید را وارد کنید
        </p>
        <img 
            width="180px"
            :src="'data:image/png;base64,'+qrCode"
            alt=""
        >
    </div>
</template>

<script>
    export default {
        name:'GoogleInfo',
        props:['qrCode']
    }
</script>

<style lang="scss" scoped>
.google-container{
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    width: 100%;
    align-items: center;
    p{
        font-size: clamp(11px,1vw,13px);
        font-weight: 400;
        width: 100%;
    }
}
</style>