<template>
    <div class="pass-container">
        <h3>تغییر رمز عبور</h3>
        <div class="content">
            <div class="input-container">
                        <label for="email">
                            <input placeholder="رمز عبور فعلی" v-model="data.oldPass" :type="showOldPass ? 'text':'password'">
                            <img @click="showOldPass=true" v-if="!showOldPass" src="../../../assets/icons/dontseepassword.svg" alt="">
                            <img @click="showOldPass=false" v-if="showOldPass" class="seepass" src="../../../assets/icons/seepassword.svg" alt="">
                        </label>
            </div>
            <div class="input-container">
                        <label for="email">
                            <input placeholder="رمز عبور جدید" v-model="data.newPass" :type="showNewPass ? 'text':'password'">
                            <img @click="showNewPass=true" v-if="!showNewPass" src="../../../assets/icons/dontseepassword.svg" alt="">
                            <img @click="showNewPass=false" v-if="showNewPass" class="seepass" src="../../../assets/icons/seepassword.svg" alt="">
                        </label>
            </div>
            <button @click.prevent="submit" :disabled="disabled" class="submit"> {{ disabled ? disabled :'تغییر رمز عبور '}}</button>
        </div>    
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data:{
                    oldPass:'',
                    newPass:'',
                },
                showOldPass:false,
                showNewPass:false,
            }
        },
        computed:{
            disabled(){
                let err
                    if(!this.data.oldPass){
                        err = 'پسوورد فعلی را وارد کنید'
                    }else if(!this.data.newPass){
                        err = 'پسوورد جدید را وارد کنید'
                    }else if(!this.$validPass(this.data.newPass)){
                        err = 'پسوورد باید شامل حروف کوچک و بزرگ و اعداد باشد'
                    }else if(this.data.newPass.length < 8 ){
                        err = 'پسوورد باید حداقل شامل ۸ کاراکتر باشد باشد'
                    }
                return err
            }
        },
        methods: {
            async submit() {
                
                let passSent = {
                    oldPassword: this.data.oldPass,
                    password: this.data.newPass
                }
                if (this.data.newPass){
                    this.state.loading = true
                    try {
                        const res = await this.$axios.post(`/users/change-password`, passSent)
                        if (res.message === "password changed successfully") {
                            this.$error('رمز شما تغییر یافت','','success')
                            this.data.oldPass = ''
                            this.data.newPass = ''
                        }
                    } catch (error) {
                        if (error.response.data.message === 'Old Password Is Incorrect') {
                            this.passError = 'رمز قبلی اشتباه است.'
                        }
                    }
                }
            },
        },
        mounted() {
            
        },
        beforeDestroy() {
            
        },
    }
</script>

<style lang="scss" scoped>
label{
    justify-content: space-between !important;
}
.content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.pass-container{
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px;
    width: 100%;
}
.input-container,.submit{
    width: 30% ;

}
.input-container{
    label,input{
        background: rgba(236, 238, 239, 1);
        img{
            cursor: pointer;
        }
    }
}
@media only screen and(max-width:700px){
    .pass-container{
        .content{
            flex-direction: column !important;
            gap: 16px;
            .input-container{
                width: 100% !important;
            }
        }
    }
}
</style>